import React from 'react'
import { geoData } from '@fs/zion-config'
import { colors, LayoutBand } from '@fs/zion-ui'

const EmbeddedCMSContent = () => {
  return (
    <>
      <LayoutBand color={colors.blue02}>
        <p>
          This page is here for debug purposes. It is NOT an actual Brightspot page but is implemented in the React code
          in Zion-CMS-App.
        </p>
        <p>geoCode={JSON.stringify(geoData)}.</p>
      </LayoutBand>
    </>
  )
}

export default EmbeddedCMSContent
