import React from 'react'
import { Link } from '@fs/zion-router'
import {
  BillboardText,
  Button,
  Cell,
  Divider,
  Expander,
  ExpanderHeader,
  ExpanderContent,
  Grid,
  H3,
  Image,
  LayoutBand,
  List,
  ListItem,
  Overline,
  Paragraph,
  Row,
  Subheading,
} from '@fs/zion-ui'
import { LogoRootstech } from '@fs/zion-icon'
import image from './images/GrantProfile.png'

export default function SpeedTest() {
  return (
    <LayoutBand css>
      <Grid>
        <Row>
          <Cell>
            <Image src={image} maxHeight={200} maxWidth={300} />
          </Cell>
          <Cell>
            <BillboardText>
              <H3>
                <Overline>Frontier/React Page</Overline>
                Page Speed Test
                <Subheading>
                  This test is a speed test for comparing Zion-CMS with conventional Frontier/React code
                </Subheading>
              </H3>
              <Paragraph>
                Comparing this page to its counterpart page will help inform the user on the cost of Zion-CMS
              </Paragraph>
            </BillboardText>
          </Cell>
        </Row>
        <Row>
          <Button to="https://www.rootstech.org" external Icon={LogoRootstech} emphasis="high">
            RootsTech
          </Button>
        </Row>
        <Divider />
        <Expander>
          <ExpanderHeader>This is a simple expander</ExpanderHeader>
          <ExpanderContent>This is the additional content you will get if you expand the expander.</ExpanderContent>
        </Expander>
        <Divider />
        <List>
          <ListItem
            key={1}
            dense
            overline="Overline one"
            primaryText="Item one"
            secondaryText="Item one secondary text"
          />
          <ListItem
            key={2}
            dense
            overline="Overline two"
            primaryText="Item two"
            secondaryText="Item two secondary text"
          />
        </List>
      </Grid>
      <Divider />
      <Paragraph>
        Please compare with <Link to="/zion-cms/speedtest-cms">Zion-CMS Speed Test</Link>.
      </Paragraph>
    </LayoutBand>
  )
}
