/**
 * This file is included 'as is' from the Correctional Facilities Program. Minor changes have been made
 * to keep it consistent with the coding style of the FamilySearch Web Development group. Maintenance for
 * this file should belong to Steve Collings and his Correctional Facilities Services group.
 */
import React, { useEffect } from 'react'
import $ from 'jquery'
import { css } from '@emotion/core'
import { BrightspotSharedSectionsContent } from '@fs/zion-cms'

const ulCss = css`
  li {
    cursor: pointer;
  }
`

const containerCss = css`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export default function SpellCheck() {
  const fetchAPI = () => {
    $('.search').click(() => {
      $('.places').empty()

      // Directly call places service
      fetch(
        `https://www.familysearch.org/service/standards/place/ws/places/request?pagesize=25&text=${encodeURI(
          $('.name').val()
        )}~`
      )
        .then((rsp) => {
          return rsp.json()
        })
        .then((obj) => {
          for (let i = 0; i < obj.searchResults[0].result.length; i++) {
            $('.places').append(
              `<li class="place" data-id="${obj.searchResults[0].result[i].rep.id}">${obj.searchResults[0].result[i].rep.fullDisplay.name}</li>`
            )
          }
        })
    })
  }

  // Place clicked
  $(document).on('click', '.place', (evt) => {
    const id = evt.target.getAttribute('data-id')

    // Get children places
    fetch(`https://www.familysearch.org/service/standards/place/ws/places/reps/${id}?children=true`, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })
      .then((rsp) => {
        return rsp.json()
      })
      .then((obj) => {
        $('.places').empty()
        if (obj.rep.children.length === 0) {
          $('.places').append('<li>No Children</li>')
        }

        for (let i = 0; i < obj.rep.children.length; i++) {
          // console.log(obj.rep.children[i]);
          $('.places').append(
            `<li class="place" data-id="${obj.rep.children[i].id}">${obj.rep.children[i].display.name}</li>`
          )
        }
      })
  })

  // Detect enter key
  $(document).on('keypress', (e) => {
    if (e.which === 13) $('.search').click()
  })

  useEffect(() => {
    fetchAPI()
  }, [])

  return (
    <div>
      <BrightspotSharedSectionsContent url="/spell-check/pre" site="cfprgm" />
      <div css={containerCss}>
        <input className="name" placeholder="Place-Name" />
        <button className="search" type="button">
          Search
        </button>
      </div>
      <div css={containerCss}>
        <ul className="places" css={ulCss} />
      </div>
      <BrightspotSharedSectionsContent url="/spell-check/post" site="cfprgm" />
    </div>
  )
}
