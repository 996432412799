/* eslint-disable no-underscore-dangle */
import React from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import { BrightspotProvider, BrightspotSharedSectionsContent } from '@fs/zion-cms'
import { appName } from '@fs/zion-config'
import { gql, useQuery } from '@apollo/client'
import { H1, H3, H5, Paragraph, Separator, Button } from '@fs/zion-ui'

const QUERY = gql`
  query MyQuery($path: String) @api(contextKey: "apiName") {
    RootstechPromo(path: $path) {
      promoTitle
      promoTitleAnalytics
      promoHeader
      promoDescription
      sharedSection {
        _id
      }
      buttonText
      buttonUrl
      buttonAnalytics
    }
  }
`

const RootsTechBannerPage = () => {
  const promoFlag = useFeatureFlag('rootstech_fs_promo', { appName, date: Date.now() })

  const { loading, error, data } = useQuery(QUERY, {
    variables: { path: `/${promoFlag.config?.url}` },
    context: { apiName: `${promoFlag.config?.site}:custom` },
  })
  // If we are NOT displaying the promo overlay then get out of here as quick as you can
  if (!promoFlag.isOn || !promoFlag.config?.url || !promoFlag.config?.site) {
    return <Paragraph>The Split I/O flag and config are not enabled</Paragraph>
  }
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error! ${JSON.stringify(error)}</div>
  }
  if (!data || !data?.RootstechPromo) {
    return <div>No data returned</div>
  }

  return (
    <div>
      <code>
        <ul>
          <li>data.RootstechPromo?.promoTitle={data.RootstechPromo?.promoTitle}</li>
          <li>data.RootstechPromo?.promoTitleAnalytics={data.RootstechPromo?.promoTitleAnalytics}</li>
          <li>data.RootstechPromo?.sharedSection?._id={data.RootstechPromo?.sharedSection?._id}</li>
          <li>data.RootstechPromo?.promoHeader={data.RootstechPromo?.promoHeader}</li>
          <li>data.RootstechPromo?.promoDescription={data.RootstechPromo?.promoDescription}</li>
          <li>data.RootstechPromo?.buttonText={data.RootstechPromo?.buttonText}</li>
          <li>data.RootstechPromo?.buttonUrl={data.RootstechPromo?.buttonUrl}</li>
          <li>data.RootstechPromo?.buttonAnalytics={data.RootstechPromo?.buttonAnalytics}</li>
        </ul>
      </code>
      <H3 as="div" tabIndex={0} role="button" noShrinkX growX linkName={data.RootstechPromo?.promoTitleAnalytics}>
        {data.RootstechPromo?.promoTitle || 'Promo Title (missing)'}
      </H3>
      {data.RootstechPromo?.sharedSection?._id ? (
        <BrightspotSharedSectionsContent url={data.RootstechPromo?.sharedSection?._id} site={promoFlag.config?.site} />
      ) : (
        <Paragraph>No shared section</Paragraph>
      )}
      <H5>{data.RootstechPromo?.promoHeader || 'Promo Header (missing)'}</H5>
      <Paragraph size="sm">{data.RootstechPromo?.promoDescription || 'Promo Description (missing)'}</Paragraph>
      <Separator size="sm" />
      <Button
        color="gray"
        emphasis="high"
        to={data.RootstechPromo?.buttonUrl || 'Button URL (missing)'}
        linkName={data.RootstechPromo?.buttonAnalytics || 'Button Analytics (missing)'}
      >
        {data.RootstechPromo?.buttonText || 'Button Text (missing)'}
      </Button>
    </div>
  )
}

export default () => {
  const promoFlag = useFeatureFlag('rootstech_fs_promo', { appName, date: Date.now() })
  if (!promoFlag?.config?.site) {
    return (
      <Paragraph>
        <H1>RootsTech Banner Page</H1>
        No site configured in experiment
      </Paragraph>
    )
  }

  return (
    <div>
      <BrightspotProvider sites={[promoFlag?.config?.site]} hasCustomContent>
        <code>
          RootsTech Banner Page
          <ul>
            <li>promoFlag.isOn={promoFlag.isOn ? 'True' : 'False'}</li>
            <li>promoFlag.config?.site={promoFlag.config?.site}</li>
            <li>promoFlag.config?.url={promoFlag.config?.url}</li>
          </ul>
        </code>
        <RootsTechBannerPage />
      </BrightspotProvider>
    </div>
  )
}
